import { Client, ActivationState, IMessage, StompSubscription } from '@stomp/stompjs'
import { checkVersion } from '../utils/utilities'
import { App } from 'vue';

export type WebSocketManager = {
  stompClient: Client | null,
  userToken: string | undefined,
  connect: () => Promise<void>,
  disconnect: () => void,
  subscribe: (destination: string, callback: (message: IMessage) => void) => Promise<any>,
  publish: (publishOptions: any) => Promise<void>,
  checkConnection: () => Promise<void>,
  isWebsocketConnected: () => boolean,
  checkUserToken: () => Promise<void>,
  attemptReconnect: () => Promise<void>
}

const MAX_CONNECTION_RETRIES = 5

export default {
  install(app: App): void {
    const log = app.config.globalProperties.$log
    const user = app.config.globalProperties.$user

    const webSocketManager: WebSocketManager = {
      stompClient: null,
      userToken: undefined,

      async connect(): Promise<void> {
        if (this.isWebsocketConnected()) {
          return
        }
        const userToken = await user.getToken()
        if (this.userToken && this.userToken !== userToken) {
          this.disconnect()
        }
        this.userToken = userToken

        this.stompClient = new Client({
          brokerURL: `wss://${window.location.host}/api/ws-chat`,
          connectHeaders: {
            Authorization: `Bearer ${userToken}`
          }
        })
        if (import.meta.env.DEV) {
          this.stompClient.debug = log.debug
        }
  
        this.stompClient.activate()
      },

      disconnect(): void {
        this.stompClient?.deactivate()
      },

      async subscribe(destination, callback): Promise<StompSubscription> {
        await this.checkConnection()

        return this.stompClient!.subscribe(destination, (message) => {
          checkVersion(message)
    
          callback(message)
        })
      },

      async publish(publishOptions): Promise<void> {
        await this.checkConnection()

        this.stompClient!.publish(publishOptions);
      },

      async checkConnection(): Promise<void> {
        if (!this.isWebsocketConnected()) {
          await this.checkUserToken()
        }

        if (!this.isWebsocketConnected()) {
          await this.attemptReconnect()
        }
      },

      isWebsocketConnected(): boolean {
        return !!this.stompClient &&
          this.stompClient.state === ActivationState.ACTIVE &&
          this.stompClient.connected
      },

      async checkUserToken(): Promise<void> {
        const userToken = await user.getToken()
        if (userToken !== this.userToken) {
          this.userToken = userToken
          this.connect()
          await new Promise((resolve) => setTimeout(resolve, 1500))
        }
      },

      async attemptReconnect(): Promise<void> {
        let retries = 0
        while (retries < MAX_CONNECTION_RETRIES && !this.stompClient!.connected) {
          this.stompClient!.activate()
          await new Promise((resolve) => setTimeout(resolve, 2000))
          retries++
        }
  
        if (retries === MAX_CONNECTION_RETRIES) {
          throw new Error('Cannot connect to websocket, max retries reached')
        }
      }
    };

    app.config.globalProperties.$websocket = webSocketManager
  }
};