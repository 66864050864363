import * as Sentry from '@sentry/vue';
import { App } from 'vue';

export function initSentry(app: App): void {
  Sentry.init({
    app,
    dsn: 'https://a608267f979587462c3d3eb0e06241fd@o4508047340142592.ingest.de.sentry.io/4508086299983953',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/(?!login\.golan\.io).*\.golan\.io/, 'https://word-add-in.predictice.com'],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: __APP_ENV__,
    release: __APP_VERSION__,
    beforeSend(event, hint) {
      const originalError: any = hint.originalException

      // Ignore RichApi.Error because it doesn't provide any useful information
      // This error happen after the user selects an invalid range, but it doesn't prevent the app from working
      if (
        originalError &&
        originalError.message === 'GeneralException' &&
        originalError.name === 'RichApi.Error'
      ) {
        return null
      }

      if (originalError && originalError.debugInfo) {
        event.extra = {
          ...event.extra,
          'Word debug info': originalError.debugInfo,
        };
      }
  
      return event
    }
  });
}
