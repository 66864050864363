import { App } from "vue"

export class Router {
  /**
   * Navigate to a new URL by pushing an entry in the history stack
   * @param {string} path Path to navigate to. Must start with a '/'
   * @param {object} query key/values of URL query parameters
   */
  push(path: string, query?: { [key: string]: string }) {
    window.location.assign(this.getHashPath(path, query))
  }

  /**
   * Navigate to a new URL by replacing the current entry in the history stack
   * @param {string} path Path to navigate to. Must start with a '/'
   * @param {object} query key/values of URL query parameters
   */
  replace(path: string, query?: { [key: string]: string }) {
    window.location.replace(this.getHashPath(path, query))
  }

  getHashPath(path: string, query?: { [key: string]: string }) {
    let hashQuery = ''
    if (query) {
      hashQuery = '?'
      for (const key in query) {
        hashQuery += `${key}=${encodeURI(query[key])}`
      }
    }
    return `/#${path}${hashQuery}`
  }

  absoluteURL(path: string, query?: { [key: string]: string }) {
    return `${window.location.origin}${this.getHashPath(path, query)}`
  }
}

export class Route {
  get currentRoute() {
    const hashSplit = window.location.hash.split('?')
    if (hashSplit.length) {
      return hashSplit[0].slice(1)
    }
    return ''
  }

  get query() {
    const hashSplit = window.location.hash.split('?')
    return hashSplit.length ? new URLSearchParams(hashSplit[1]) : new URLSearchParams('')
  }
}

export default {
  install: (app: App) => {
    app.config.globalProperties.$router = new Router()
    app.config.globalProperties.$route = new Route()
  }
}
