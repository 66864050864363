<template>
  <div data-cy="toaster" class="toast-container">
    <h4 v-if="title" data-cy="toaster-title" :class="titleClasses">{{ title }}</h4>
    <span v-if="message" data-cy="toaster-body" v-html="message" />
  </div>
</template>

<script lang="ts">
export default {
  name: 'ToasterBody',
  components: {},
  props: {
    title: {
      type: String,
      required: false,
      default: undefined
    },
    message: {
      type: String,
      required: false,
      default: undefined
    }
  },
  computed: {
    titleClasses (): string[] {
      const titleClasses = ['c-fontprimary-7']
      if (!this.message) {
        titleClasses.push('mr-bottom-0')
      }
      return titleClasses
    }
  }
}
</script>
<style scoped lang="scss">
.toast-container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  max-width: 100%;

  span {
    @include c-font-primary(4);
  }
}
</style>
