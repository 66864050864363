import axios from 'axios'
import { App } from 'vue'

export default {
  install: (app: App) => {
    const instance = axios.create({
      baseURL: '/api',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    app.config.globalProperties.$axios = instance
  }
}
