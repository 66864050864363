import { App } from 'vue'
import xss from 'xss'

export default {
  install: (app: App) => {
    app.directive('xss-filter', {
      beforeMount(el, binding, vnode) {
        const handler = function (e: Event) {
          const target = e.target as HTMLInputElement;
          if (target.value !== xss(target.value)) {
            target.value = target.value.slice(0, target.value.length - 1)
            vnode.el.dispatchEvent(new CustomEvent('input'))
          }
        }
        el.addEventListener('input', handler)
      }
    })

    app.directive('sanitized-html', (el, binding) => {
      el.innerHTML = xss(binding.value)
    })
  }
}
