<template>
  <div class="close-container">
    <div class="close-button" @click.prevent="$emit('click', $event)">
      <img
        a="Fermer la fenêtre"
        src="/img/icons/croix.svg"
         width="14"
         height="14" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToasterClose'
}
</script>

<style scoped lang="scss">
.close-container {
  img {
    filter: $color-primary-filters;
  }
}
</style>
