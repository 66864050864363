import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js'
import { checkVersion } from '../utils/utilities.js'
import { AxiosInstance, AxiosResponse } from 'axios'
import { Router } from '../plugins/router'
import { App } from 'vue'

interface UserProperties {
  firstname?: string;
  lastname?: string;
  function?: string;
  roles?: string[];
}

export class UserInfo {
  id?: string;
  email?: string;
  firstname?: string;
  lastname?: string;
  function?: string;
  roles: string[] = []

  constructor(userProperties: UserProperties) {
    Object.assign(this, userProperties)
  }

  hasProperty(property: 'firstname' | 'lastname' | 'function'): boolean {
    // eslint-disable-next-line no-prototype-builtins
    return this.hasOwnProperty(property) && this[property]!.trim() !== ''
  }

  hasValidProfile(): boolean {
    return (
      this.hasProperty('firstname') && this.hasProperty('lastname') && this.hasProperty('function')
    )
  }

  hasNecessaryRoles(): boolean {
    return this.roles.includes('DOCUMENT_COMPOSE')
  }
}

export class UserInfoManager {
  auth0: Auth0Client
  axios: AxiosInstance
  router: Router

  constructor(auth0: Auth0Client, axios: AxiosInstance, router: Router) {
    this.auth0 = auth0
    this.axios = axios
    this.router = router
  }

  async isAuthenticated(): Promise<boolean> {
    return await this.auth0.isAuthenticated()
  }

  login(): void {
    this.auth0.loginWithRedirect()
  }

  async get(): Promise<UserInfo | undefined> {
    if (!(await this.getToken())) {
      return undefined
    }
    const { data } = await this.axios.get('/user/info')
    return new UserInfo(data)
  }

  getToken(): Promise<string> | undefined {
    try {
      return this.auth0.getTokenSilently()
    } catch (e) {
      this.logout()
      this.router.push('/')
      return undefined
    }
  }

  async refresh(): Promise<void> {
    try {
      await this.auth0.getTokenSilently({ cacheMode: 'off' })
      await this.get()
    } catch (e) {
      this.logout()
      this.router.push('/')
    }
  }

  logout(): void {
    Office.context.ui.displayDialogAsync(
      this.router.absoluteURL('/logout'),
      { height: 10, width: 10, displayInIframe: true },
      (asyncResult) => {
        const dialog = asyncResult.value
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, async () => {
          // @ts-ignore
          const keys: string[] = await this.auth0.cacheManager.getCacheKeys()
          keys.forEach((key) => localStorage.removeItem(key))

          dialog.close()
          this.router.push('/')
        })
      }
    )
  }

  doLogout(): void {
    this.auth0.logout({
      logoutParams: {
        returnTo: this.router.absoluteURL('/logged-out')
      }
    })
  }
}

export default async () => {
  const auth0 = await createAuth0Client({
    domain: import.meta.env.AUTH0_DOMAIN,
    clientId: import.meta.env.AUTH0_CLIENT_ID,
    authorizationParams: {
      audience: import.meta.env.AUTH0_AUDIENCE,
      redirect_uri: `${window.location.origin}/#/signed-in`
    },
    cacheLocation: 'localstorage',
    useRefreshTokens: true
  })
  return {
    install: (app: App) => {
      const axios = app.config.globalProperties.$axios
      const router = app.config.globalProperties.$router
      const userInfoManager = new UserInfoManager(auth0, axios, router)
      app.config.globalProperties.$user = userInfoManager

      // Automatically set Authorization header for all axios calls
      axios.interceptors.request.use(
        async function (config) {
          if (await auth0.isAuthenticated()) {
            const token = await userInfoManager.getToken()
            config.headers.Authorization = `Bearer ${token}`
          }
          return config
        },
        function (error) {
          return Promise.reject(error)
        }
      )

      axios.interceptors.response.use(
        (response : AxiosResponse) => {
          checkVersion(response)

          return response
        },
        function (error) {
          checkVersion(error.response)

          // React to authentication errors
          const code = parseInt(error.response?.status)
          if (code === 401 || error.name === 'ExpiredAuthSessionError') {
            userInfoManager.logout()
            router.push('/')
          } else if (code === 403) {
            router.push('/unauthorized')
          }
          return Promise.reject(error)
        }
      )
    }
  }
}
