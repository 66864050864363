<template>
  <main id="app-content" :class="{ 'color-background': isNonConnectedLanding }">
    <template v-if="activeLayout">
      <component :is="activeLayout">
        <component :is="currentView" @layout="updateLayout" />
      </component>
    </template>
    <template v-else>
      <component :is="currentView" @layout="updateLayout" />
    </template>
  </main>
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent } from 'vue'

/*
  Using this manual routing "hack" because of a bug when using vue-router
  and the office.js library
  https://github.com/vuejs/vue-router/issues/3154
 */
const routes : Record<string, any> = {
  '/': defineAsyncComponent(() => import('./views/LandingView.vue')),
  '/chat': defineAsyncComponent(() => import('./views/ChatView.vue')),
  '/error': defineAsyncComponent(() => import('./views/ErrorView.vue')),
  '/logged-out': defineAsyncComponent(() => import('./views/auth/LoggedOutView.vue')),
  '/login': defineAsyncComponent(() => import('./views/auth/LoginView.vue')),
  '/logout': defineAsyncComponent(() => import('./views/auth/LogoutView.vue')),
  '/signed-in': defineAsyncComponent(() => import('./views/auth/SignedInView.vue')),
  '/tools': defineAsyncComponent(() => import('./views/ToolsView.vue')),
  '/unauthorized': defineAsyncComponent(() => import('./views/UnauthorizedView.vue'))
}
import 'overlayscrollbars/styles/overlayscrollbars.css'

export default defineComponent({
  name: 'App',
  data() {
    return {
      activeLayout: undefined as string | undefined,
      currentRoute: this.$route.currentRoute as string
    }
  },
  computed: {
    currentView() {
      return routes[this.currentRoute || '/']
    },
    isNonConnectedLanding() : boolean {
      return this.currentRoute === '/'
    }
  },
  mounted() {
    window.addEventListener('hashchange', () => {
      this.currentRoute = this.$route.currentRoute
    })
    window.addEventListener('resize', this.appHeight)
    this.appHeight()
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.appHeight)
  },
  methods: {
    updateLayout(layout : string) : void {
      this.activeLayout = layout
    },
    appHeight() : void {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
  }
})
</script>

<style lang="scss">
:root {
  --vh: 1vh;
}
</style>

<style lang="scss" scoped>
#app-content {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $color-primary-background;

  &.color-background {
    background-color: unset;
    background-image: url('/img/white-bg.webp');
    background-size: cover;
    background-position-x: 50%;
  }
}
</style>
